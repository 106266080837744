import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/1592.jpg","alt":null}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/area-i-_-relief.jpg","alt":null}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/area-i-_-jug.jpg","alt":null}}></MDXTag></MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"Top Finds of Area F","key":"top-finds-of-area-f","parentKey":"area-f"};

  